<template>
    <validation-observer tag="div" ref="observer" v-if="!loading">
        <b-row>
            <b-col cols="4">
                <zw-select-group v-model="form.finished_configurable_category"
                                 :options="getCategories()"
                                 name="finished_configurable_category"
                                 label-prefix="settings.configurable."
                                 validate="required"
                                 text-field="name"
                                 value-field="id"
                ></zw-select-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('settings.configurable.configurable_attributes_order')">
                    <multiselect v-model="form.configurable_attributes_order"
                                 name="configurable_attributes_order"
                                 :searchable="true"
                                 :options="Object.keys(getAttributes())"
                                 :multiple="true"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" class="text-xs-center">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-configurable',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            form: {
                finished_configurable_category: null,
                configurable_attributes_order: [],
            },
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getSettings', 'getAttributes']),
        ...mapGetters('Category', ['getCategories']),
        shown() {
            this.loading = true
            const categories = this.$store.dispatch('Category/fetchCategories')
            this.form.finished_configurable_category = this.getSettings()['finished_configurable_category'] || null
            if (typeof this.getSettings()['configurable_attributes_order'] != "undefined") {
                this.form.configurable_attributes_order = JSON.parse(this.getSettings()['configurable_attributes_order']) || []
            }

            Promise.all([categories])
                .finally(() => {
                    this.loading = false
                });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )

                this.$store.dispatch('CommonData/fetchCommonData')
                    .finally(() => {
                        this.shown()
                    })

            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>